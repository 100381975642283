import { For } from "solid-js";
import CategoryIcon from "./categoryIcon.tsx";
import ENABLED_CATEGORIES from "../assets/js/enabledCategories.ts";

export default function CategoryRow() {
	return (
		<>
			<For each={ENABLED_CATEGORIES}>
				{enabledCategory => (
					<CategoryIcon
						value={enabledCategory.name}
						icon={enabledCategory.icon}
					/>
				)}
			</For>
		</>
	);
}
