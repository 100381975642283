import { Switch, Match } from "solid-js";
import { $currentCategory, $currentCategoryItemCounts } from "../stores/category.ts";
// @ts-ignore
import { useStore } from "@nanostores/solid";

interface Props {
	value: string;
	icon: string;
}

const svgStyles = {
	"border-b-[3px]": true,
	"border-transparent": true,
	"w-8": true,
	"h-auto": true,
	"max-h-full": true,
	"transition-all": true,
	"duration-500": true,
	"ease-in-out": true,
}

export default function CategoryIcon(props: Props) {
	const itemCategoryCounts = useStore($currentCategoryItemCounts)
	const currentCategory = useStore($currentCategory);
	const isSelected = () => import.meta.env.SSR ? false : props.value === currentCategory();

	const onChangeCategory = (event: HTMLElement) => {
		$currentCategory.set(event.target.value);
		navigator?.vibrate(50);
	};

	const getCountBubbleColor = (count: number): string => {
		if (count > 30) {
			return "text-high-item-count";
		}

		if (count > 20) {
			return "text-medium-item-count";
		}

		return "text-low-item-count";
	};

	return (
		<label class="inline-flex cursor-pointer gap-2 h-10 m-0">
			<input
				class="hidden duration-300"
				type="radio"
				name="category"
				value={props.value}
				checked={isSelected()}
				onChange={onChangeCategory}
			/>
			<span
				classList={{
					"w-4": true,
					"inline-block": true,
					"align-top": true,
					"font-bold": true,
					"text-base": true,
					"select-none": true,
					[getCountBubbleColor(itemCategoryCounts()?.[props.value])]: true,
					"hidden": itemCategoryCounts()?.[props.value] == 0
				}}
				textContent={itemCategoryCounts()?.[props.value] ?? ""}
			/>
			<Switch>
				<Match when={props.icon === "trolley"}>
					<svg
						classList={{
							...svgStyles,
							"border-b-secondary": isSelected(),
							"scale-110": isSelected(),
							"animate-bounce-up": isSelected(),
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 576 512"
					>
						<path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
					</svg>
				</Match>
				<Match when={props.icon === "carrot"}>
					<svg
						classList={{
							...svgStyles,
							"border-b-secondary": isSelected(),
							"scale-110": isSelected(),
							"animate-bounce-up": isSelected(),
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="16px"
						viewBox="0 0 512 512"
					>
						<path d="M346.7 6C337.6 17 320 42.3 320 72c0 40 15.3 55.3 40 80s40 40 80 40c29.7 0 55-17.6 66-26.7c4-3.3 6-8.2 6-13.3s-2-10-6-13.2c-11.4-9.1-38.3-26.8-74-26.8c-32 0-40 8-40 8s8-8 8-40c0-35.7-17.7-62.6-26.8-74C370 2 365.1 0 360 0s-10 2-13.3 6zM244.6 136c-40 0-77.1 18.1-101.7 48.2l60.5 60.5c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-55.3-55.3 0 .1L2.2 477.9C-2 487-.1 497.8 7 505s17.9 9 27.1 4.8l134.7-62.4-52.1-52.1c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L199.7 433l100.2-46.4c46.4-21.5 76.2-68 76.2-119.2C376 194.8 317.2 136 244.6 136z" />
					</svg>
				</Match>
				<Match when={props.icon === "house"}>
					<svg
						classList={{
							...svgStyles,
							"border-b-secondary": isSelected(),
							"scale-110": isSelected(),
							"animate-bounce-up": isSelected(),
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="16px"
						viewBox="0 0 576 512"
					>
						<path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
					</svg>
				</Match>
				<Match when={props.icon === "circle"}>
					<svg
						classList={{
							...svgStyles,
							"border-b-secondary": isSelected(),
							"scale-110": isSelected(),
							"animate-bounce-up": isSelected(),
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="16px"
						viewBox="0 0 512 512"
					>
						<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
					</svg>
				</Match>
				<Match when={props.icon === "compact-disc"}>
					<svg
						classList={{
							...svgStyles,
							"border-b-secondary": isSelected(),
							"scale-110": isSelected(),
							"animate-bounce-up": isSelected(),
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="16px"
						viewBox="0 0 512 512"
					>
						<path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 32a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm-96-32a96 96 0 1 0 192 0 96 96 0 1 0 -192 0zM96 240c0-35 17.5-71.1 45.2-98.8S205 96 240 96c8.8 0 16-7.2 16-16s-7.2-16-16-16c-45.4 0-89.2 22.3-121.5 54.5S64 194.6 64 240c0 8.8 7.2 16 16 16s16-7.2 16-16z" />
					</svg>
				</Match>
				<Match when={props.icon === "boxes-stacked"}>
					<svg
						classList={{
							...svgStyles,
							"border-b-secondary": isSelected(),
							"scale-110": isSelected(),
							"animate-bounce-up": isSelected(),
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="16px"
						viewBox="0 0 576 512"
					>
						<path d="M248 0H208c-26.5 0-48 21.5-48 48V160c0 35.3 28.7 64 64 64H352c35.3 0 64-28.7 64-64V48c0-26.5-21.5-48-48-48H328V80c0 8.8-7.2 16-16 16H264c-8.8 0-16-7.2-16-16V0zM64 256c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H224c35.3 0 64-28.7 64-64V320c0-35.3-28.7-64-64-64H184v80c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V256H64zM352 512H512c35.3 0 64-28.7 64-64V320c0-35.3-28.7-64-64-64H472v80c0 8.8-7.2 16-16 16H408c-8.8 0-16-7.2-16-16V256H352c-15 0-28.8 5.1-39.7 13.8c4.9 10.4 7.7 22 7.7 34.2V464c0 12.2-2.8 23.8-7.7 34.2C323.2 506.9 337 512 352 512z" />
					</svg>
				</Match>
				<Match when={props.icon === "school"}>
					<svg
						classList={{
							...svgStyles,
							"border-b-secondary": isSelected(),
							"scale-110": isSelected(),
							"animate-bounce-up": isSelected(),
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="1rem"
						viewBox="0 0 640 512"
					>
						<path d="M337.8 5.4C327-1.8 313-1.8 302.2 5.4L166.3 96H48C21.5 96 0 117.5 0 144V464c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48H473.7L337.8 5.4zM256 416c0-35.3 28.7-64 64-64s64 28.7 64 64v96H256V416zM96 192h32c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16V208c0-8.8 7.2-16 16-16zm400 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H512c-8.8 0-16-7.2-16-16V208zM96 320h32c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H96c-8.8 0-16-7.2-16-16V336c0-8.8 7.2-16 16-16zm400 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H512c-8.8 0-16-7.2-16-16V336zM232 176a88 88 0 1 1 176 0 88 88 0 1 1 -176 0zm88-48c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H336V144c0-8.8-7.2-16-16-16z" />
					</svg>
				</Match>
				<Match when={props.icon === "print"}>
					<svg
						classList={{
							...svgStyles,
							"border-b-secondary": isSelected(),
							"scale-110": isSelected(),
							"animate-bounce-up": isSelected(),
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="1rem"
						viewBox="0 0 512 512"
					>
						<path d="M128 0C92.7 0 64 28.7 64 64l0 96 64 0 0-96 226.7 0L384 93.3l0 66.7 64 0 0-66.7c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0L128 0zM384 352l0 32 0 64-256 0 0-64 0-16 0-16 256 0zm64 32l32 0c17.7 0 32-14.3 32-32l0-96c0-35.3-28.7-64-64-64L64 192c-35.3 0-64 28.7-64 64l0 96c0 17.7 14.3 32 32 32l32 0 0 64c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-64zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
					</svg>
				</Match>
			</Switch>
		</label>
	);
}
